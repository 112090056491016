import './header.scss'

export function Header() {
    return (
        <div className="header">
            <div className="container">
                <a href="https://www.singular-it.de/" target="_blank" rel="noreferrer">
                    <img id="logo" src={process.env.PUBLIC_URL + '/logo.png'} alt="SingularIT logo"></img>
                </a>
            </div>
        </div>
    )
}