import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { createTheme, ThemeProvider } from '@material-ui/core'


const theme = createTheme({
    palette: {
      primary: {
        light: '#ffad57',
        main: '#f07d27',
        dark: '#b74f00',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffad57',
        main: '#f07d27',
        dark: '#b74f00',
        contrastText: '#fff',
      },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
