import './app.scss';
import React from 'react'
import { Header } from './header/Header'
import { UserForm } from './user-form/UserForm'
import { requestDataDownload, requestSyntheticData } from './services/api'
import { UsageDescription } from './usage-description/UsageDescription'
import { LinearProgress } from '@material-ui/core'
import { ImageArea } from './image-area/ImageArea';
import { ScoreView } from './score-view/ScoreView';
import { Footer } from './footer/Footer'

function App() {
  const [controlsHidden, setControlsHidden] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState(null)
  const [resultData, setResultData] = React.useState(null)

  function handleUserFormSubmit(formData) {
    setErrorMsg(null)
    setLoading(true)
    requestSyntheticData(formData).then((data) => {
      if (data.error) {
        setErrorMsg(data.error)
      } else {
        setResultData({
          heatmapOriginal: data.heatmapOriginal,
          heatmapSynthetic: data.heatmapSynthetic,
          scoreCramers: data.scoreCramers,
          scoreSDV: data.scoreSDV,
          downloadID: data.downloadID
        })
        setControlsHidden(true)
      }
    }).catch(e => {
      setErrorMsg(e.toString())
    }).finally(() => {
      setLoading(false)
    })
  }

  function handleReturnToUserInput() {
    setResultData(null)
    setControlsHidden(false)
  }

  function triggerDataDownload() {
    requestDataDownload(resultData.downloadID)
  }

  return (
    <div className="app-content">
      <Header></Header>
      <div className="main-content">
        <div className="container headline">
          <h2>CTGAN Synthetic Data Generator</h2>
          <div className="divider"></div>
        </div>

        <div className={"container input-area " + (controlsHidden ? "hidden" : null)}>
          <UserForm onUserSubmit={handleUserFormSubmit}></UserForm>
          <a href="mailto: synthetic.data@singular-it.de" className="btn btn-outline-singularit contact">Contact Us</a>
          <UsageDescription></UsageDescription>
        </div>
        
        <div className="container extras-wrapper">
          { loading ? (
            <LinearProgress />
          ) : null }
          { errorMsg ? (
            <code>{errorMsg}</code>
          ) : null}
          { controlsHidden ? (
            <div className="control-buttons">
              <button onClick={handleReturnToUserInput} className="btn btn-outline-singularit">Change Parameters</button>
              <button onClick={triggerDataDownload} className="btn btn-singularit" download>Download Your Data</button>
            </div>
        ) : null}
        </div>
        {resultData ? (
          <>
            <ImageArea heatmapOriginal={resultData.heatmapOriginal} heatmapSynthetic={resultData.heatmapSynthetic}></ImageArea>
            <ScoreView scoreCramers={resultData.scoreCramers} scoreSDV={resultData.scoreSDV}></ScoreView>
          </>
        ) : null}
      </div>
      <Footer></Footer>
    </div>
  )
}

export default App;
