import './footer.scss'

export function Footer() {
    return (
        <div className="footer">
            <h5>CTGAN Synthetic Data Generator</h5>
            <p className="long-text">This project is the bachelor thesis of Melle Mendikowski, written with the support of singularIT for the University of Leipzig. You are welcome to contact via  <a href="mailto: synthetic.data@singular-it.de">email</a> if you have any questions.</p>
            <span className="copyright">©singularIT 2021</span>
        </div>
    )
}